<template>
    <div class="test">模块2</div>
</template>

<script>
export default {
    name:'order',
}
</script>

<style>
.test{
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgb(180, 175, 175);
    height: 100%;
    background: beige;
}
</style>